footer {
  background-color: rgb(20, 36, 32);
  width: 100%;
  position: relative;
  z-index: 4;
  color: white;

  * {
    text-align: left;
  }

  a { 
    color: whitesmoke; 
    cursor: pointer;
    text-decoration: underline;
  }
  padding: 50px 300px;

  display: grid;
  grid-template-areas: 
    "kontakt links"
    "credits links";
  row-gap: 50px;
  column-gap: 50px;
  justify-items: stretch;

  .contact-section {
    grid-area: kontakt;
    h4 {
      font-size: 2em;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 15px;
    }
  }


  .link-section {
    grid-area: links;
    padding: 0 0 0 100px;
    border-left: 2px solid white;
    display: grid;

    $icon-size: 60px;

    .link-icon {
      width: $icon-size;
      height: $icon-size;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .email { 
      background-image: url("../../../media/svgs/email.svg"); 
      border-radius: 50px;
      background-color: white;
      background-size: 60%;
    }
    .instagram { background-image: url("../../../media/svgs/instagram.svg"); }
    .facebook { background-image: url("../../../media/svgs/facebook.svg"); }
    .twitter { background-image: url("../../../media/svgs/twitter.svg"); }

  }

  .credit-section {
    grid-area: credits;
  }

}


@media screen and (max-width: 1200px) {

  footer {
    padding: 50px 100px;

    .link-section {
      padding: 0 0 0 50px;
    }

  }

}

@media screen and (max-width: 650px) {

  footer {

    padding: 50px 50px;
    display: block;

    .link-section {
      border: none;
      padding: 0px 0px;
      margin: 50px 0px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

    }

  }

}

@media screen and (max-width: 400px) {

  footer {

    padding: 50px 30px;

  }

}