
.gallery-section {

  $size: 100%;
  $max-height: 750px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: $max-height;
  gap: 50px;

  .card-link { 
    width: $size;
    height: auto;
    max-height: $max-height;

    img {
      width: $size;
      height: $size;
      object-fit: contain;
    }

  }

}


@media screen and (max-width: 1400px) {

  $max-height: 550px;
  .gallery-section {
    grid-template-rows: $max-height;
    gap: 30px;

    .card-link { 
      height: auto;
      max-height: $max-height;
    }

  }

  .gallery-section .card-link-wrapper .card-link {
    .heading-info {
      h4 {
        font-size: 1em;
      }
      p {
        font-size: 0.9em;
      }
    }
    .abbreviated-info p {
      font-size: 0.9em;
    }
  } 
}

@media screen and (max-width: 1000px) {
  $max-height: 450px;
  .gallery-section {
    grid-template-rows:  $max-height;
    gap: 10px;

    .card-link { 
      height: auto;
      max-height: $max-height;
    }
  }
}

@media screen and (max-width: 800px) {
  
  $size: 450px;
  
  .gallery-section {
    display: flex;
    flex-direction: column;
    gap: 100px; 
    align-items: center;
    justify-content: center;


    .card-link { 
      width: 100%;
      max-width: $size;
      height: auto;
      max-height: $size + 200px;
  
      img {
        width: 100%;
        height: $size;
        object-fit: contain;
      }

      section {
        padding: 5%;
      }
  
    }

  }
}