
  .scroll-indicator {
    $size: 75px;
    bottom: 0;
    left: calc(50% - #{$size / 2});
    position: absolute;
    width: $size;
    height: $size;
    background-image: url("./../../../media/svgs/mouse-simple.svg");
    background-repeat: no-repeat;
    background-size: contain;
    animation: scroll-indicator 1.8s infinite;
  }

  
@keyframes scroll-indicator {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}