@import "./../../../../css/constants.scss";

$landing-upper-padding: 100px;

$landing-section-min-height: 700px;

$gradient: linear-gradient(33deg, transparent 0%, transparent 8%,rgba(9, 9, 9,0.02) 8%, rgba(9, 9, 9,0.02) 59%,transparent 59%, transparent 100%),
linear-gradient(234deg, transparent 0%, transparent 32%,rgba(225, 225, 225,0.02) 32%, rgba(225, 225, 225,0.02) 99%,transparent 99%, transparent 100%),
linear-gradient(312deg, transparent 0%, transparent 24%,rgba(93, 93, 93,0.02) 24%, rgba(93, 93, 93,0.02) 58%,transparent 58%, transparent 100%),
linear-gradient(90deg, $accent-color, rgb(204, 127, 127));



@font-face {
  font-family: "Arvo";
  src: url("./../../../../media/fonts/Arvo/Arvo-Bold.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Arvo";
  src: url("./../../../../media/fonts/Arvo/Arvo-Regular.ttf");
  font-weight: 500;
}




.landing-section {
  position: relative;
  z-index: 4;
  background-color: $accent-color;
  background-image: $background-gradient;
  display: flex;
  height: auto;
  min-height: 100vh;
}

.landing-section main {
  width: 100%;
  height: fit-content;
  position: relative;
  
  article {
    
    height: fit-content;
    width: 100%;
    padding: $landing-upper-padding 10%;

    display: flex;
    color: white;
    align-items: center;

    .abbreviation {

      font-family: 'Arvo';
      writing-mode: vertical-rl;
      height: fit-content;
      font-size: 5rem;
      transform: rotateZ(180deg);

    }

    .heading {
      font-size: 8rem;
      line-height: 1;
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 10px;
        word-break:break-word;
        hyphens: auto;
      }

    }


  }


  .transparent-text {
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;

    h1 {
      font-size: 40em;
      line-height: 0.9;
    }

  }

}

.introduction-section {
  margin: 0;
  $decoration-section-width: 10vw;
  $background-color: whitesmoke;
  background-color: $background-color;
  width: 100%;
  display: flex;
  position: relative;
  z-index: 3;

  .decoration-section {
    background-color: $background-color;
    position: relative;
    z-index: 1;
    width: $decoration-section-width;
    min-width: $decoration-section-width;
    height: auto;

    display: flex;
    justify-content: space-between;

    .color {
      $width: 6px;
      height: 0%;
      width: $width;
      background-color: rgb(204, 127, 127);
      z-index: 0;
      position: absolute;
      top: 0;
      left: calc((#{$decoration-section-width} / 2) - (#{$width} / 2));
    }

  }

  .content-section {
    padding-right: $decoration-section-width / 2;

    article {
      margin: 200px 0px;
  
      .checkpoint {
        position: relative;
        z-index: 3;

        $size: 20px;
        width: $size;
        height: $size;
        border-radius: $size / 2;
        background-color: rgb(204, 127, 127);

        left: calc(-#{$decoration-section-width} / 2 - #{$size} / 2);
      }

      .content {
        display: flex;
        h2.span-heading {
          flex: 1;
          display: flex;
          flex-direction: column;
          line-height: 1;
          font-size: 5em;
          align-items: flex-end;
        }
        h2.non-span-heading {
          display: none;
        }
        p {
          text-align: left;
          flex: 2;
          margin-left: 40px;
          font-size: 1.8em;
          display: flex;
          flex-direction: column;
    
          span {
            margin-bottom: 20px;
          }
    
        }
      }
    }

  }

}

.posts-header-section {
  position: relative;
  height: auto;
  min-height: 700px;
  background: $gradient;
  z-index: 4;

  overflow: hidden;
  color: white;

  .transparent-text {
    top: 0;
    position: absolute;
    z-index: 0;
    width: fit-content;
    height: auto;
    overflow: hidden;
    h1 {
      line-height: 0.9;
      font-size: 40em;
    }
  }

  article {
    margin: 100px 0;
    padding: 0 100px;
    width: 100%;
    height: auto;

    * {
      text-align: center;
    }

    h2 {
      font-size: 7em;
      margin-bottom: 50px;
    }
    h3 {
      font-size: 4em;
      opacity: 0.8;
    }
  }

}

@import "./../../Template/css/constants.scss";

$post-margin: 200px;
$post-height: 350px;

.posts-section {
  background-color: whitesmoke;
  position: relative;
  z-index: 3;
  height: auto;

  section {
      
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .mouse-icon {
      width: 100px;
      height: 100px;

      background-image: url("./../../../../media/svgs/mouse.svg"); 
      background-repeat: no-repeat;
      background-size: contain;
    }

    p {
      margin-top: 50px;
      font-size: 1.5em;
    }

  }


  .posts-container {

    padding: 200px;
    display: grid;

    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;

    .post-wrapper {

      margin: 0 auto;

      .card-link {
        display: block;
        height: $post-height;
        width: $post-height;
        height: $post-height;
  
        img {
          transition: height 0.1s ease-in-out, width 0.1s ease-in-out;
          height: $post-height;
          width: $post-height;
        }

        border-bottom: none;

        &::after {
          $padding: 50px;
          content: attr(data-title);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: $padding;
          width: calc(100% - $padding * 2);
          height: calc(100% - $padding * 2);
          background-color: rgba(0, 0, 0, 0.7);
          color: white;
          position: absolute;
          z-index: 99;
          top: -100%;
          transition: top 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        }

        &:hover {
          &::after {
            top: 0;
          }
        }
  
        section {
          display: none;
        }
      }

    }

  }

  > article {
    padding: 100px 200px;
    padding-top: 0;
    h2 {
      text-align: center;
      font-size: 3em;
    }
  }

}

.conclusion-section {
  position: relative;
  z-index: 3;
  background-image: $gradient;
  background-color: $accent-color;
  width: 100%;
  min-height: 100vh;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 200px;

  q {
    font-size: 3em;
  }

}


@import "./home-responsive.scss";