@import "./../../../../css/constants.scss";



$image-size: 500px;
$heading-padding: 100px;

.template-landing-section {

  background-color: $accent-color;
  background-image: $background-gradient;
  width: 100%;
  height: auto;
  min-height: 700px;
  overflow: hidden;

  position: relative;


  .transparent-text {
    top: 0;
    position: absolute;
    z-index: 0;
    width: fit-content;
    height: auto;
    overflow: hidden;
    h1 {
      width: 100vw;
      font-size: 20em;
      line-height: 1;
      position: relative;
      left: 35vw;
    }
  }

  article {

    width: 100%;
    min-height: 50%;
    height: auto;
    padding: 200px $heading-padding;
    overflow-x: hidden;

    > h1 {
      z-index: 3;
      font-size: 5em;
      color: white;
    }

  }

  .scroll-indicator {
    bottom: 50px;
  }

}

.template-image-section {

  height: 0;

  img {
    position: relative;
    z-index: 2;
  
    top: calc(-#{$image-size} / 2);
    width: $image-size;
    object-fit: contain;
  
    margin-left: calc(50% - #{$image-size / 2});
  
    box-shadow: 0px 30px 100px $soft-shadow-color;
  }

}

.template-content-section {
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  background-color: whitesmoke;
  padding: 100px 300px;
  padding-top: $image-size / 2 + 100px;

  .upload-date {
    color: rgba(100, 100, 100, .9);
  }

  article {

    h2 {
      font-size: 2em;
      margin: 50px 0 20px 0;
    }
    p {
      font-size: 1.1em;
      margin-bottom: 20px;
    }

    ul, ol {
      margin-bottom: 25px;
    }

  }

}

@import "./page-template-responsive.scss";