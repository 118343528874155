
$offset: 30px;
$size: 50px;

nav {
  position: fixed;
  z-index: 99999;


  ul.navigation-links {
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.075, 0.075);
    transition: right 0.5s, opacity 0.5s;
    top: 2 * $offset + $size;
    position: fixed;
    width: calc(100% - #{$offset * 2});
    max-width: 400px;
    height: calc(100% - #{3 * $offset + $size});
    background-color: white;

    padding: 50px 50px;
    list-style-type: none;
    
    overflow-y: scroll;
    overflow-x: hidden;

    li {
      cursor: pointer;

      margin-right: 0;
      margin-left: 0;
      transition: margin-left 0.2s ease, margin-right 0.2s ease;
      &:hover, &:focus {
        margin-left: 10px;
        margin-right: -10px;
      }

      margin-bottom: 20px;
      font-size: 1.7rem;
      font-weight: 300;

      background-image: linear-gradient(33deg, transparent 0%, transparent 8%,rgba(9, 9, 9,0.02) 8%, rgba(9, 9, 9,0.02) 59%,transparent 59%, transparent 100%),
      linear-gradient(234deg, transparent 0%, transparent 32%,rgba(225, 225, 225,0.02) 32%, rgba(225, 225, 225,0.02) 99%,transparent 99%, transparent 100%),
      linear-gradient(312deg, transparent 0%, transparent 24%,rgba(93, 93, 93,0.02) 24%, rgba(93, 93, 93,0.02) 58%,transparent 58%, transparent 100%),
      linear-gradient(90deg, rgb(204, 127, 127),rgb(245, 153, 153));
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;

    }
  
  }

  ul.navigation-links.open {
    right: $offset;
    opacity: 1;
  }

  ul.navigation-links.closed {
    //top: 0px ;
    right: -100%;
    opacity: 0;
  }

  .menu-button {
    z-index: 4;
    width: $size;
    height: $size;
    background-color: white;
    position: fixed;
    right: $offset;
    top: $offset;
    background-image: url("./menu_black_24dp.svg");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    cursor: pointer;
  }

}


$aside-width: 400px;

.navigation-link-detailed .sub-menu {
  position: fixed;
  background-color: white;
  width: 100%;


  .navigation-back-button {
    margin: 50px 0 0 50px;
    height: 30px;
    width: 30px;
    background-image: url("./arrow_back_ios_red_24dp.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  ul {
    padding: 50px 50px;
    list-style-type: none;

    .navigation-link {
      cursor: pointer;

      margin-right: 0;
      margin-left: 0;
      transition: margin-left 0.2s ease, margin-right 0.2s ease;
      &:hover, &:focus {
        margin-left: 10px;
        margin-right: -10px;
      }

      margin-bottom: 20px;
      font-size: 1.7rem;
      font-weight: 300;

      background-image: linear-gradient(33deg, transparent 0%, transparent 8%,rgba(9, 9, 9,0.02) 8%, rgba(9, 9, 9,0.02) 59%,transparent 59%, transparent 100%),
      linear-gradient(234deg, transparent 0%, transparent 32%,rgba(225, 225, 225,0.02) 32%, rgba(225, 225, 225,0.02) 99%,transparent 99%, transparent 100%),
      linear-gradient(312deg, transparent 0%, transparent 24%,rgba(93, 93, 93,0.02) 24%, rgba(93, 93, 93,0.02) 58%,transparent 58%, transparent 100%),
      linear-gradient(90deg, rgb(204, 127, 127),rgb(245, 153, 153));
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;

    }

  }

}

.landing-section aside {
  animation: aside-scroll-in 1.5s;
}

.navigation-link-detailed .sub-menu {
  height: 100vh;
  position: absolute;
  z-index: 2;
  top: 0;
  transition: right 0.5s ease;
  right: -$aside-width;
}

.navigation-link-detailed .sub-menu.menu-open {
  right: 0;
}

.navigation-link-detailed .sub-menu.menu-closed {
  right: -$aside-width;
}


@media screen and (max-width: 400px) {

  nav ul.navigation-links, .navigation-link-detailed .sub-menu ul {
    padding: 30px;

    li, .navigation-link {
      font-size: 1.5rem;  
    } 

  }

  .navigation-link-detailed .sub-menu .navigation-back-button {
    margin: 30px 0 0 30px;
  }

}