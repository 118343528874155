@import "./../../../css/constants.scss";

.transparent-text.transparent-text-404 {
  position: absolute;
  top: 0;
  right: 0;
}

.page, .transparent-text {
  width: 100%;
  height: 100vh;
  min-height: 700px;
  overflow:hidden;
}

.transparent-text h1 {
  font-size: 20em;
  line-height: 0.9;
  opacity: 0.7;
}

.page {
  padding: 100px;
  color: white;

  background: $background-gradient;
  background-color: $accent-color;

  display: flex;
  align-items: center;
  justify-content: center;

  article {
      
    position: relative;
    z-index: 1;
    width: fit-content;
    height: fit-content;
    text-align: center;
    > * {
      text-align: center;
      font-size: 1.5em;
    }

    a {
      color: rgb(231, 231, 231);
    }

    h1 {
      font-size: 8em;
    }

  }

}


@media screen and (max-width: 670px) {

  .page {
    padding: 30px;

    article {
      h1 {
        font-size: 4em;
      }
      > * {
        font-size: 1em;
      }
    }

  }

  .transparent-text h1 {
    display: none;
  }

}