.image-section {
  display: flex;
  gap: 50px;
  width: 100%;
  margin-top: 100px;
  justify-content: space-evenly;

  img {
    width: 40%;
  }

}

@media screen and (max-width: 700px) {
  .image-section {
    flex-direction: column;

    img {
      width: 100%;
    }

  }
}